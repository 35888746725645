import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import moment from "moment";
import AxiosInstance from "../../AxiosInstance";
import sendToast from "../../../components/Toast/sendToast";
import CustomerDetailsViews from "./Views/CustomerDetails";
import sendSwal from "../../../components/Swal/sendSwal";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import showToast from "../../../components/Toast/Toster";
import { Typography, Grid } from "@mui/material";
import { DeleteIcone, EditIcon } from "../../../components/Icon/Index";
import { Col } from "reactstrap";
import RecurringCharges from "../../../components/Recurring Charge/RecurringCharges";
import dayjs from "dayjs";
import swal from "sweetalert";

function CustomerDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { companyName } = useParams();
  const [data, setData] = useState();
  const [open, setOpen] = useState({ isOpen: false, propertyData: null });
  const [loader, setLoader] = useState(true);
  const [modelOpen, setModelOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [configOpen, setConfigOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tokenDecode, setTokenDecode] = useState({});
  const [contract, setContract] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customersData, setcustomersData] = useState([]);
  const [countData, setCountData] = useState(0);

  useEffect(() => {
    handleAuth(navigate, location);
  }, []);

  const [DateDecode, setDateDecode] = useState({});
  const fetchData = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
      setDateDecode(res.themes);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [rowsPerPage, page, search]);

  const [dateFormat, setDateFormat] = useState("MM-DD-YYYY");
  useEffect(() => {
    const handleDateFormat = () => {
      if (DateDecode?.Format) {
        setDateFormat(DateDecode?.Format);
      } else {
        setDateFormat("MM-DD-YYYY");
      }
    };

    handleDateFormat();
  }, [DateDecode]);

  const [tenantCardsModal, setTenantCardsModal] = useState(false);
  const [customersDetails, setCustomersDetails] = useState([]);
  const [balanceData, setBalanceData] = useState();

  const getData = async () => {
    try {
      const res = await AxiosInstance.get(`/customer/${location?.state?.id}`);
      setData(res?.data?.data);
      setCustomersDetails([res?.data?.data]);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [location?.state?.id]);

  const handlePropertyDelete = async (id) => {
    sendSwal().then(async (deleteReason) => {
      if (deleteReason) {
        try {
          const res = await AxiosInstance.delete(`/location/${id}`, {
            data: { DeleteReason: deleteReason },
          });
          if (res?.data?.statusCode === 200) {
            setTimeout(() => {
              showToast.success(res?.data?.message);
            }, 500);
            getData();
          } else if (res?.data?.statusCode === 202) {
            setTimeout(() => {
              showToast.error(res?.data?.message);
            }, 500);
          } else {
            showToast.error("", res?.data?.message, "error");
          }
        } catch (error) {
          showToast.error(error?.message);
          console.error("Error: ", error?.message);
        }
      } else {
        showToast.success("Property is safe!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  const [activeTabId, setActiveTabId] = useState(1);
  const handleClick = (tabId) => {
    setActiveTabId(tabId);
  };
  const [activeTabIdMain, setActiveTabIdMain] = useState(1);
  const handleMainClick = (tabId) => {
    setActiveTabIdMain(tabId);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const moreActiontoggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    const fetchQuote = async () => {
      if (data && data?.CustomerId) {
        try {
          const response = await AxiosInstance.get(
            `/quote/get_quotes_customer/${
              localStorage.getItem("CompanyId") || tokenDecode?.companyId
            }/${data?.CustomerId}`
          );
          if (response?.data?.statusCode === 200) {
            setQuotes(response?.data?.data);
          }
        } catch (err) {
          console.error("Error to fetching quote data: ", err.message);
        }
      }
    };

    fetchQuote();
  }, [data, tokenDecode]);

  useEffect(() => {
    const fetchContract = async () => {
      if (data && data?.CustomerId) {
        try {
          const response = await AxiosInstance.get(
            `/contract/get_contract_customer/${
              localStorage.getItem("CompanyId") || tokenDecode?.companyId
            }/${data?.CustomerId}`
          );
          setContract(response?.data?.data);
        } catch (err) {
          console.error("Error to fetching contract data: ", err.message);
        }
      }
    };
    fetchContract();
  }, [data, tokenDecode]);

  useEffect(() => {
    const fetchInvoice = async () => {
      if (data && data.CustomerId) {
        try {
          const response = await AxiosInstance.get(
            `/invoice/get_invoice_customer/${
              localStorage.getItem("CompanyId") || tokenDecode?.companyId
            }/${data?.CustomerId}`
          );
          if (response?.data?.statusCode === 200) {
            setInvoice(response?.data?.data);
          }
        } catch (err) {
          console.error("Error to fetching contract data: ", err.message);
        }
      }
    };
    fetchInvoice();
  }, [data, tokenDecode]);

  const handleQuoteNavigate = (id) => {
    if (companyName) {
      navigate(`/${companyName}/quotes-detail`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/quotes-detail"],
        },
      });
    } else {
      navigate(`/staff-member/worker-quotes-details`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/worker-quotes-detailss"],
        },
      });
    }
  };

  const handleContractNavigate = (id) => {
    if (companyName) {
      navigate(`/${companyName}/contractdetails`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/contractdetails"],
        },
      });
    } else {
      navigate(`/staff-member/worker-contract-details`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/worker-contract-details"],
        },
      });
    }
  };

  const handleInvoiceNavigate = (id) => {
    if (companyName) {
      navigate(`/${companyName}/invoice-details`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/invoice-details"],
        },
      });
    } else {
      navigate(`/staff-member/worker-invoice-detail`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/worker-invoice-detail"],
        },
      });
    }
  };

  const sendMail = async () => {
    try {
      setLoader(true);
      const response = await AxiosInstance.post(
        `/customer/send_mail/${data?.CustomerId}`
      );
      if (response?.data?.statusCode === 200) {
        showToast.success(response?.data?.message);
      } else {
        sendToast(response?.data?.message);
      }
    } catch (error) {
      console.error("Error to send mail");
    } finally {
      setModelOpen(false);
      setLoader(false);
    }
  };

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);
  const menuItems = [
    {
      label: (
        <>
          <RequestQuoteOutlinedIcon
            className="icones-dropdown"
            style={{ fontSize: "18px" }}
          />
          Quote
        </>
      ),
      onClick: () => {
        navigate(`/${companyName}/add-quotes`, {
          state: {
            Customer: data,
            CustomerId: data?.CustomerId,
            navigats: [...location?.state?.navigats, "/add-quotes"],
          },
        });
      },
    },
    {
      label: (
        <>
          <WorkOutlineOutlinedIcon
            className="icones-dropdown"
            style={{ fontSize: "18px" }}
          />
          Contract
        </>
      ),
      onClick: () => {
        navigate(`/${companyName}/add-contract`, {
          state: {
            Customer: data,
            CustomerId: data?.CustomerId,
            navigats: [...location?.state?.navigats, "/add-contract"],
          },
        });
      },
    },
    {
      label: (
        <>
          <FileCopyOutlinedIcon
            className="icones-dropdown"
            style={{ fontSize: "18px" }}
          />
          Invoice
        </>
      ),
      onClick: () => {
        navigate(`/${companyName}/invoicetable`, {
          state: {
            CustomerId: data?.CustomerId,
            navigats: [...location?.state?.navigats, "/invoicetable"],
          },
        });
      },
    },
    {
      label: (
        <>
          <MarkEmailReadOutlinedIcon
            className="icones-dropdown"
            style={{ fontSize: "18px" }}
          />
          Resend Invitation
        </>
      ),
      onClick: () => {
        setModelOpen(true);
      },
    },
  ];

  const [selectChargeDropDown, setSelectChargetDropDown] = useState(false);
  const [selectCard, setSelectCard] = useState(false);
  const [selectDayOfMonth, setSelectDayOfMonth] = useState(false);
  const [accountTypeName, setAccountTypeName] = useState("");
  const [addBankAccountDialogOpen, setAddBankAccountDialogOpen] =
    useState(false);
  const [openRecurringDialog, setOpenRecurringDialog] = useState(false);
  const [LiabilityAccounts, setLiabilityAccounts] = useState([]);
  const [AssetAccounts, setAssetAccounts] = useState([]);
  const [EquityAccounts, setEquityAccounts] = useState([]);
  const [RevenueAccounts, setRevenueAccounts] = useState([]);
  const [ExpenseAccounts, setExpenseAccounts] = useState([]);
  const [recAccounts, setRecAccounts] = useState([]);
  const [oneTimeAccounts, setoneTimeAccounts] = useState([]);
  const [selectedFrequency, setSelectedFrequency] = useState("");
  const handleClose = () => {
    setOpenRecurringDialog(false);
  };
  const toggles1 = () => setSelectChargetDropDown(!selectChargeDropDown);
  const toggles2 = () => setSelectCard(!selectCard);
  const toggles3 = () => setSelectDayOfMonth(!selectDayOfMonth);
  const AddNewAccountName = async (accountName) => {
    toggleAddBankDialog();
    setAccountTypeName(accountName);
  };

  const toggleAddBankDialog = () => {
    setAddBankAccountDialogOpen((prevState) => !prevState);
  };
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedAccountId, setSelectedAccountId] = useState("");

  const CompanyId = localStorage?.getItem("CompanyId");
  const CustomerId = location?.state?.id;

  const [formData, setFormData] = useState({
    CompanyId: CompanyId,
    CustomerId: CustomerId,
    account_id: selectedAccountId,
    description: "",
    amount: "",
    day_of_month: "",
    frequency_interval: "",
    frequency: selectedFrequency,
  });

  const resetForm = () => {
    setFormData({
      CompanyId: CompanyId,
      CustomerId: CustomerId,
      account_id: selectedAccountId,
      description: "",
      amount: "",
      day_of_month: "",
      frequency_interval: "",
      frequency: selectedFrequency,
    });
  };

  const Account = async () => {
    try {
      const dataToPost = {
        CompanyId: CompanyId,
        CustomerId: CustomerId,
        ...formData,
      };
      const res = await AxiosInstance.post(`/recurring-charge`, dataToPost);

      if (res.data.statusCode === 200) {
        showToast.success("Charge Added Successfully");

        setFormData({});
        setSelectedFrequency("");
        setSelectedAccount("");
        selectedAccountId("");
      } else {
        showToast.error(res.data.message);
      }
    } catch (error) {
      console.error("Error: ", error.message);
    } finally {
      getTabData();
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddButtonClick = () => {
    Account();
    setPaymentOpen(false);
  };

  const handleConfigClick = () => {
    Account();
    setConfigOpen(false);
  };

  const fetchAccounts = async () => {
    if (localStorage.getItem("CompanyId") || tokenDecode?.companyId) {
      try {
        const res = await AxiosInstance.get(
          `/account/accounts/${
            localStorage.getItem("CompanyId") || tokenDecode?.companyId
          }`
        );
        if (res.data.statusCode === 200) {
          const filteredData1 = res.data.data.filter(
            (item) => item.account_type === "Asset"
          );
          const filteredData2 = res.data.data.filter(
            (item) => item.account_type === "Liability"
          );
          const filteredData3 = res.data.data.filter(
            (item) => item.account_type === "Equity"
          );
          const filteredData4 = res.data.data.filter(
            (item) => item.account_type === "Revenue"
          );
          const filteredData5 = res.data.data.filter(
            (item) => item.account_type === "Expense"
          );
          setAssetAccounts(filteredData1);
          setLiabilityAccounts(filteredData2);
          setEquityAccounts(filteredData3);
          setRevenueAccounts(filteredData4);
          setExpenseAccounts(filteredData5);
        } else if (res.data.statusCode === 201) {
          setRecAccounts();
          setoneTimeAccounts();
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };
  useEffect(() => {
    fetchAccounts();
  }, [localStorage.getItem("CompanyId") || tokenDecode?.companyId]);

  const handleAccountSelection = (accountId, accounttype, accountName) => {
    setSelectedAccount(accountName); // Store selected account name
    setSelectedAccountId(accountId); // Store selected account type

    // Also update formData with the selected account and account type
    setFormData({
      ...formData,
      account_id: accountId,
    });

    setSelectChargetDropDown(false); // Close the dropdown after selection
  };

  const getTabData = async () => {
    setLoader(true);
    try {
      const res = await AxiosInstance.get(`/recurring-charge/${location.state.id}`, {
        params: {
          pageSize: rowsPerPage,
          pageNumber: page,
          search: search || "",
        },
      });
      if (res?.data) {
        setcustomersData(res.data.data || []);
        setCountData(res.data.totalCount || 0);
        setBalanceData(res?.data?.totalAmount);
      } else {
        console.error("No data received from the server.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getTabData();
  }, [rowsPerPage, search, page]);
  const handleEditClick = (id) => {
    if (companyName) {
      navigate(`/${companyName}/add-customer`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/add-customer"],
        },
      });
    } else {
      navigate(`/staff-member/add-customer`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/add-customer"],
        },
      });
    }
  };
  const handleDelete = (id) => {
    sendSwal().then(async (deleteReason) => {
      if (deleteReason) {
        try {
          const response = await AxiosInstance.delete(`/customer/${id}`, {
            data: { DeleteReason: deleteReason },
          });

          if (response?.data?.statusCode === 200) {
            showToast.success(response?.data?.message);

            setcustomersData((prevData) =>
              prevData.filter((customer) => customer.CustomerId !== id)
            );

            getData(tokenDecode?.companyId);
          } else {
            showToast.warning(response?.data?.message);
          }
        } catch (error) {
          console.error("Error:", error);
          showToast.error(error?.message || "An error occurred");
        }
      } else {
        showToast.success("Customer is safe!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });

    setTimeout(() => {
      const deleteButton = document?.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  const cellData = customersData?.map((item, index) => {
    const properties = item?.location || [];

    let propertyDisplay;
    if (properties.length === 1) {
      const property = properties[0];
      propertyDisplay = `${property?.Address || "N/A"} ,${
        property?.City || "N/A"
      } ,${property?.State || "N/A"} ,${property?.Country || "N/A"},${
        property?.Zip || "N/A"
      } `;
    } else {
      propertyDisplay = `${properties.length} ${
        properties?.length > 1 ? "Properties" : "Property"
      }`;
    }

    const CycleData = () => {
      if (
        item?.frequency?.toLowerCase() === "every n months" ||
        item?.frequency?.toLowerCase() === "every n weeks"
      ) {
        const frequencyValue = item?.frequency_interval;
        return item.frequency.replace("n", frequencyValue);
      }
      return item?.frequency || "N/A";
    };

    return {
      key: item?.CustomerId,
      value: [
        page * rowsPerPage + index + 1,

        CycleData(),
        item?.account_name || "N/A",
        item?.amount || "N/A",
        item?.description || "N/A",
        moment(item.createdAt).format(dateFormat),
        item?.nextDueDate
          ? moment(item.nextDueDate).isValid()
            ? moment(item.nextDueDate).format(dateFormat)
            : "n/a"
          : "n/a",

        // <>
        //   <EditIcon
        //     onClick={(e) => {
        //       e.stopPropagation();
        //       handleEditClick(item?.CustomerId);
        //     }}
        //   />

        //   <DeleteIcone
        //     className="customerEditImgToEdit"
        //     onClick={(e) => {
        //       e.stopPropagation();
        //       handleDelete(item?.CustomerId);
        //     }}
        //   />
        // </>,
      ],
    };
  });
  // const CollapseData = customersData?.map((item) => ({
  //   createdAt: item?.createdAt || "No details provided",
  // }));
  const CollapseData = customersData?.map((item) => {
    return (
      <Grid className="d-flex gap-4 mt-3 mb-3 w-100" key={item.id}>
        <Col className="card col-8 productDetaillTable">
          <Grid
            className="card-body w-100"
            style={{ backgroundColor: "#D8E7EE" }}
          >
            <Grid className="d-flex w-100 flex-row justify-content-between gap-2">
              <Typography className="text-blue-color">Description:</Typography>
              <Typography
                style={{
                  backgroundColor: "white",
                  padding: "0.2rem 0.5rem",
                  borderRadius: "0.25rem",
                  width: "100%",
                  marginBottom: "7px",
                }}
                className="text-blue-color"
              >
                {/* {item.Description || "N/A"} */}
              </Typography>
            </Grid>
          </Grid>
        </Col>
      </Grid>
    );
  });

  const cardToggle = () => setTenantCardsModal(!tenantCardsModal);
  const [frequencyDropdownOpen, setFrequencyDropdownOpen] = useState(false);

  const toggleFrequencyDropdown = () =>
    setFrequencyDropdownOpen(!frequencyDropdownOpen);

  const handleFrequencySelection = (option) => {
    setSelectedFrequency(option);
    setFormData({ ...formData, frequency_interval: "", frequency: option }); // Reset interval if needed
  };
















  const [selectedStartDate, setSelectedStartDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const [paymentMethod, setPaymentMethod] = useState("");
  const [format, setFormat] = useState("");
  const [collectSignatureLoader, setCollectSignatureLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);

  const simulateProgress = () => {
    let simulatedProgress = 0;
    const interval = setInterval(() => {
      simulatedProgress += 5;
      setProgress(simulatedProgress);

      if (simulatedProgress >= 100) {
        clearInterval(interval);
      }
    }, 100);
  };


  const fetchReportData = async () => {
    setLoader(true);
    try {
      // Get the companyId
      const companyId =
        localStorage?.getItem("CompanyId") || tokenDecode?.companyId;

      if (!companyId) {
        console.error("Company ID is missing!");
        setLoader(false);
        return;
      }

      // API call to fetch report data with pagination params
      const getRes = await AxiosInstance.get(
        `/report/get_payment_data/${companyId}`,
        {
          params: {
            pageSize: rowsPerPage,
            pageNumber: page,
            selectedStartDate: selectedStartDate,
            selectedEndDate: selectedEndDate,
            method: paymentMethod,
          },
        }
      );



      if (getRes?.data?.statusCode === 200) {
        // Assuming the API response provides data in a format like this:
        // { statusCode: 200, data: { reportData: [], totalPages: number, totalCount: number } }
        setReportData(getRes.data.data);
        setTotalPages(getRes.data.totalPages); // Set total pages based on the response
        setCountData(getRes.data.totalCount || 0);
      } else {
        console.error("API Response error:", getRes.data);
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchReportData();
  }, [page, rowsPerPage, selectedStartDate, selectedEndDate, paymentMethod]); // Properly include selectedStartDate and selectedEndDate

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedStartDate || !selectedEndDate || !paymentMethod || !format) {
      alert("Please fill in all required fields.");
      return;
    }

    const formattedStartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    const requestData = {
      selectedStartDate: formattedStartDate,
      selectedEndDate: formattedEndDate,
      method: paymentMethod,
      ispdf: format === "pdf",
      iscsv: format === "csv",
      isexcel: format === "excel",
    };

    try {
      const willDelete = await swal({
        title: "Are you sure?",
        text: "You want to download!",
        icon: "warning",
        buttons: ["Cancel", "Process"],
        dangerMode: true,
      });
      if (willDelete) {
        setCollectSignatureLoader(true);
        setProgress(0);
        simulateProgress();
        try {
          const res = await AxiosInstance.post(
            `/report/generate-report/${
              localStorage?.getItem("CompanyId") || tokenDecode?.companyId
            }`,
            requestData
          );

          if (res.data.statusCode === 200) {
            const fileName = res?.data?.fileName;
            const url = `http://localhost:9278/cdn/upload/${fileName}`;

            fetch(url)
              .then((response) => {
                if (!response.ok)
                  throw new Error("Network response was not ok");
                return response.blob();
              })
              .then((blob) => {
                setFileData(blob);
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download =
                  format === "csv"
                    ? "output.csv"
                    : format === "pdf"
                    ? "payment_document.pdf"
                    : "quotes_document.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              })
              .catch((error) =>
                console.error(
                  "There was a problem with the fetch operation:",
                  error
                )
              );
          } else {
            console.error("Failed to generate report:", res.data);
          }
        } catch (error) {
          console.error("Error:", error);
          showToast.error("Failed to send the PDF. Please try again.");
        } finally {
          setCollectSignatureLoader(false);
          setProgress(0);
        }
      }
    } catch (error) {
      console.error("Error processing the request:", error);
    }
  };

  const cellFinancialData = reportData?.map((item, index) => ({
    key: item?.EmailId || index,
    value: [
      page * rowsPerPage + index + 1,
      item?.method || "N/A",
      item?.amount || "N/A",
      // typeof item?.date === "string"
      //   ? item?.date
      //   : item?.date?.toString() || "N/A",
      moment(item.date).format(dateFormat),
      typeof item?.cc_type === "string" ? item?.cc_type : "N/A",
      // Ensure date is a valid string (if it's an object, fallback to "N/A")

      `${
        item?.first_name && typeof item.first_name === "string"
          ? item.first_name
          : item.getCustomerDetails?.FirstName || "N/A"
      } 
     ${
       item?.last_name && typeof item.last_name === "string"
         ? item.last_name
         : item.getCustomerDetails?.LastName || ""
     }`,
       <>
          <EditIcon
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(item?.CustomerId);
            }}
          />

          <DeleteIcone
            className="customerEditImgToEdit"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item?.CustomerId);
            }}
          />
        </>,
    ],
  }));

  const collapseData = reportData?.map((item) => ({
    createdAt: item?.createdAt || "No details provided",
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <CustomerDetailsViews
        loader={loader}
        navigate={navigate}
        data={data}
        dropdownOpen={dropdownOpen}
        moreActiontoggle={moreActiontoggle}
        companyName={companyName}
        location={location}
        setModelOpen={setModelOpen}
        setPaymentOpen={setPaymentOpen}
        setConfigOpen={setConfigOpen}
        activeTabId={activeTabId}
        activeTabIdMain={activeTabIdMain}
        handleClick={handleClick}
        handleMainClick={handleMainClick}
        quotes={quotes}
        invoice={invoice}
        contract={contract}
        setOpen={setOpen}
        handlePropertyDelete={handlePropertyDelete}
        handleQuoteNavigate={handleQuoteNavigate}
        moment={moment}
        handleContractNavigate={handleContractNavigate}
        handleInvoiceNavigate={handleInvoiceNavigate}
        open={open}
        modelOpen={modelOpen}
        paymentOpen={paymentOpen}
        configOpen={configOpen}
        getData={getData}
        loading={loading}
        sendMail={sendMail}
        toggleDropdown={toggleDropdown}
        menuItems={menuItems}
        dateFormat={dateFormat}
        tokenDecode={tokenDecode}
        oneTimeAccounts={oneTimeAccounts}
        recAccounts={recAccounts}
        ExpenseAccounts={ExpenseAccounts}
        RevenueAccounts={RevenueAccounts}
        EquityAccounts={EquityAccounts}
        AssetAccounts={AssetAccounts}
        LiabilityAccounts={LiabilityAccounts}
        openRecurringDialog={openRecurringDialog}
        addBankAccountDialogOpen={addBankAccountDialogOpen}
        accountTypeName={accountTypeName}
        handleAddButtonClick={handleAddButtonClick}
        handleConfigClick={handleConfigClick}
        handleInputChange={handleInputChange}
        AddNewAccountName={AddNewAccountName}
        toggles1={toggles1}
        toggles2={toggles2}
        toggles3={toggles3}
        handleClose={handleClose}
        setAddBankAccountDialogOpen={setAddBankAccountDialogOpen}
        formData={formData}
        setFormData={setFormData}
        fetchAccounts={fetchAccounts}
        selectChargeDropDown={selectChargeDropDown}
        selectCard={selectCard}
        selectDayOfMonth={selectDayOfMonth}
        handleAccountSelection={handleAccountSelection}
        selectedAccount={selectedAccount}
        search={search}
        setSearch={setSearch}
        cellData={cellData}
        // CollapseData={CollapseData}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        countData={countData}
        CollapseData={CollapseData}
        customersData={customersData}
        cardToggle={cardToggle}
        customersDetails={customersDetails}
        balanceData={balanceData}
        handleFrequencySelection={handleFrequencySelection}
        toggleFrequencyDropdown={toggleFrequencyDropdown}
        selectedFrequency={selectedFrequency}
        frequencyDropdownOpen={frequencyDropdownOpen}
        setSelectedAccountId={setSelectedAccountId}
        setSelectedAccount={setSelectedAccount}
        setSelectedFrequency={setSelectedFrequency}
        resetForm={resetForm}





















        selectedEndDate={selectedEndDate}
        selectedStartDate={selectedStartDate}
        // isEdited={isEdited}
        // handleDialogOpen={handleDialogOpen}
        // handleDialogClose={handleDialogClose}
        // openDialog={openDialog}
        // dialogData={dialogData}
        // customersData={customersData}
        // dateFormat={dateFormat}
        reportData={reportData}
        setFormat={setFormat}
        setPaymentMethod={setPaymentMethod}
        format={format}
        totalPages={totalPages}
        handleSubmit={handleSubmit}
        setSelectedStartDate={setSelectedStartDate}
        setSelectedEndDate={setSelectedEndDate}
        paymentMethod={paymentMethod}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        setCountData={setCountData}
        collectSignatureLoader={collectSignatureLoader}
        progress={progress}
        cellFinancialData={cellFinancialData}

      />

      <RecurringCharges
        isOpen={tenantCardsModal}
        toggle={cardToggle}
        customersData={customersDetails}
        companyId={tokenDecode?.companyId}
        fetchCustomerData={getData}
        amount={balanceData}
      />
    </>
  );
}

export default CustomerDetails;
