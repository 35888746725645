// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-blue-color.red-border:focus {
    border-color: #063164;
    /* Keep border red when focused */
}`, "",{"version":3,"sources":["webpack://./src/Views/Admin/Communications/Send E-mail/style.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,iCAAiC;AACrC","sourcesContent":[".text-blue-color.red-border:focus {\n    border-color: #063164;\n    /* Keep border red when focused */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
