import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import clientdetails from "../../../../assets/White-sidebar-icon/Customer.svg";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import truck from "../../../../assets/image/icons/truck.svg";
import InvoiceBlue from "../../../../assets/Blue-sidebar-icon/Invoice.svg";
import PaymentBlue from "../../../../assets/Orange-icons/payment.svg";
import CustomerProperty from "../Customer-Property";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import {
  DeleteIcone,
  EditIcon,
  LoaderComponent,
  NavigateButton,
} from "../../../../components/Icon/Index";
import "./style.css";
import { Grid } from "@mui/material";
import BlueButton from "../../../../components/Button/BlueButton";
import AllDropdown from "../../../../components/Dropdown/Dropdown";
import { Typography } from "@mui/material";
import InputText from "../../../../components/InputFields/InputText";
import AxiosInstance from "../../../AxiosInstance";
import WhiteButton from "../../../../components/Button/WhiteButton";
import {
  JobberPagination,
  JobberSearch,
  JobberTable,
} from "../../../../components/MuiTable";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import RecurringCharges from "../../../../components/Recurring Charge/RecurringCharges";
// import Swal from "sweetalert2";
// import { useParams } from "react-router-dom";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import ContactImage from "../../../../assets/Blue-sidebar-icon/Contact.svg";
import CustomerEmail from "../../../../assets/Blue-sidebar-icon/CustomerEmail.svg";
import { NoDataFound } from "../../../../components/Contract Component/Index";
import Financial from "./Financial";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
const CustomerDetails = ({
  loader,
  navigate,
  data,
  dropdownOpen,
  companyName,
  location,
  setModelOpen,
  setPaymentOpen,
  cardToggle,
  activeTabId,
  handleClick,
  handleMainClick,
  activeTabIdMain,
  quotes,
  invoice,
  contract,
  setOpen,
  handlePropertyDelete,
  handleQuoteNavigate,
  moment,
  handleContractNavigate,
  handleInvoiceNavigate,
  open,
  modelOpen,
  paymentOpen,
  getData,
  loading,
  sendMail,
  toggleDropdown,
  menuItems,
  dateFormat,
  tokenDecode,
  oneTimeAccounts,
  recAccounts,
  ExpenseAccounts,
  RevenueAccounts,
  EquityAccounts,
  AssetAccounts,
  LiabilityAccounts,
  openRecurringDialog,
  addBankAccountDialogOpen,
  accountTypeName,
  handleAddButtonClick,
  handleInputChange,
  AddNewAccountName,
  toggles1,
  handleClose,
  setAddBankAccountDialogOpen,
  formData,
  setFormData,
  fetchAccounts,
  selectChargeDropDown,
  handleAccountSelection,
  selectedAccount,
  search,
  setSearch,
  cellData,
  collapseData,
  page,
  setRowsPerPage,
  countData,
  rowsPerPage,
  CollapseData,
  customersData,
  // handleConfigClick,
  // configOpen,
  setConfigOpen,
  customersDetails,
  balanceData,
  handleFrequencySelection,
  toggleFrequencyDropdown,
  selectedFrequency,
  frequencyDropdownOpen,
  resetForm,
  setSelectedAccountId,
  setSelectedFrequency,
  setSelectedAccount,
  cellFinancialData,

  // companyName,
  // countData,
  // dateFormat,
  setPage,
  reportData,
  selectedEndDate,
  selectedStartDate,
  setFormat,
  setPaymentMethod,
  format,
  progress,
  collectSignatureLoader,
  totalPages,
  handleSubmit,
  setCountData,
  setSelectedStartDate,
  setSelectedEndDate,
  paymentMethod,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  return (
    <>
      {loader ? (
        <Grid
          className="d-flex flex-direction-row justify-content-center align-items-center Typography-5 m-5"
          style={{ height: "80vh", marginTop: "25%" }}
        >
          <LoaderComponent loader={loader} height="50" width="50" />
        </Grid>
      ) : (
        <Grid className="justify-content-center align-items-center mb-3 mt-5 client">
          <Grid className="d-flex align-items-center justify-content-between">
            <Button
              style={{
                marginRight: "10px",
                width: "50px",
                height: "40px",
                marginBottom: "10px",
                padding: "0px 0px",
                borderRadius: "4px",
                marginTop: "10px",
              }}
              onClick={() => {
                // navigate(-1);
                navigate(
                  `/${
                    companyName
                      ? companyName + "/customer"
                      : "staff-member" + "/workercustomer"
                  }`,
                  {
                    state: {
                      navigats: location?.state?.navigats.filter(
                        (item) => item !== "/customer"
                      ),
                    },
                  }
                );
              }}
              className="text-capitalize bg-button-blue-color text-white-color "
            >
              <ArrowBackOutlinedIcon />
            </Button>
            <Grid className="d-flex">
              <AllDropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                menuItems={menuItems}
              />
            </Grid>
          </Grid>

          <Card>
            <CardHeader>
              <Grid
                className="customer-header"
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid
                  className=" text-blue-color costomerUserName "
                  style={{
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "38px",
                  }}
                >
                  <Grid
                    className="bg-blue-color"
                    style={{
                      borderRadius: "50%",
                      marginRight: "10px",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <img src={clientdetails} />
                  </Grid>
                  {data?.FirstName ? data?.FirstName : "-"}&nbsp;
                  {data?.LastName ? data?.LastName : "-"}
                </Grid>
                <Grid className="customer-detail-sec">
                  <Typography className="mb-2 ">
                    <img src={ContactImage} />
                    {data?.PhoneNumber ? (
                      <a
                        className="text-blue-color"
                        style={{
                          textDecoration: "none",
                          fontSize: "14px",
                          paddingLeft: "8px",
                          fontWeight: 500,
                        }}
                        href={`tel:${data?.PhoneNumber}`}
                      >
                        {data?.PhoneNumber || "N/A"}
                      </a>
                    ) : (
                      "-"
                    )}
                  </Typography>
                  <Typography>
                    <img src={CustomerEmail} />

                    {data?.EmailAddress ? (
                      <a
                        className="text-blue-color"
                        style={{
                          textDecoration: "none",
                          fontSize: "14px",
                          paddingLeft: "8px",
                          fontWeight: 500,
                        }}
                        href={`tel:${data?.EmailAddress}`}
                      >
                        {data?.EmailAddress || "N/A"}
                      </a>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <Grid>
                {/* <Nav
                  className=" customerOverviewNav border-blue-color text-blue-color"
                  tabs
                  style={{
                    display: "flex",
                    // minWidth: "668px",
                    overflowX: "auto",
                  }}
                >
                  <NavItem>
                    <NavLink
                      className={
                        activeTabIdMain === 1 ? "active" : "text-blue-color"
                      }
                      onClick={() => handleMainClick(1)}
                      style={{ cursor: "pointer" }}
                    >
                      Summary
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTabIdMain === 2 ? "active" : "text-blue-color"
                      }
                      onClick={() => handleMainClick(2)}
                      style={{ cursor: "pointer" }}
                    >
                      Reccuring Charge
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTabIdMain === 3 ? "active" : "text-blue-color"
                      }
                      onClick={() => handleMainClick(3)}
                      style={{ cursor: "pointer" }}
                    >
                      Reccuring Payment
                    </NavLink>
                  </NavItem>
                </Nav> */}
                <Nav
                  className="main-tab border-blue-color text-blue-color"
                  tabs
                  style={{
                    display: "flex",
                    overflowX: "auto",
                  }}
                >
                  <NavItem>
                    <NavLink
                      className={`nav-link text-blue-color ${
                        activeTabIdMain === 1 ? "active" : ""
                      }`}
                      onClick={() => handleMainClick(1)}
                      style={{ cursor: "pointer" }}
                    >
                      Summary
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`nav-link text-blue-color ${
                        activeTabIdMain === 2 ? "active" : ""
                      }`}
                      onClick={() => handleMainClick(2)}
                      style={{ cursor: "pointer" }}
                    >
                      Recurring Charge
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`nav-link text-blue-color ${
                        activeTabIdMain === 3 ? "active" : ""
                      }`}
                      onClick={() => handleMainClick(3)}
                      style={{ cursor: "pointer" }}
                    >
                      Recurring Payment
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`nav-link text-blue-color ${
                        activeTabIdMain === 4 ? "active" : ""
                      }`}
                      onClick={() => handleMainClick(4)}
                      style={{ cursor: "pointer" }}
                    >
                      Over View
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`nav-link text-blue-color ${
                        activeTabIdMain === 5 ? "active" : ""
                      }`}
                      onClick={() => handleMainClick(5)}
                      style={{ cursor: "pointer" }}
                    >
                      Transaction
                    </NavLink>
                  </NavItem>
                </Nav>
              </Grid>
              <TabContent
                style={{ padding: "15px 1px 0px 0px" }}
                activeTab={activeTabIdMain}
                className="text-start"
              >
                <TabPane tabId={1}>
                  <Grid>
                    <Grid>
                      <Grid
                        className="my-2 detail-card flexForDetail"
                        style={{ border: "none", gap: "12px" }}
                      >
                        <Col
                          xs={12}
                          sm={6}
                          md={12}
                          lg={12}
                          className="first-card add_new_pxroperty_card"
                        >
                          <Grid
                            className="address propertyAddBoxScroll p-2 border-blue-color"
                            style={{
                              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                              borderRadius: "8px",
                              border: "0.5px solid ",
                            }}
                          >
                            <CardHeader
                              className="d-flex justify-content-between align-items-center"
                              style={{ border: "none" }}
                            >
                              <Typography
                                style={{ fontWeight: 600 }}
                                className="text-blue-color property_tag heading-five"
                              >
                                Properties
                              </Typography>

                              <Grid className="plusNewPropertyToAddPPro">
                                <BlueButton
                                  className="bg-button-blue-color "
                                  style={{
                                    padding: "3px 10px 3px 10px",
                                    fontSize: "14px",
                                  }}
                                  onClick={() =>
                                    setOpen({
                                      isOpen: true,
                                      propertyData: null,
                                    })
                                  }
                                  label="+ New Property"
                                />
                              </Grid>
                            </CardHeader>
                            <CardBody className="card-body client-details property_addNew boxScrollHEre">
                              <Table
                                borderless
                                className="propertyTableAndItScroll"
                              >
                                <TableHead>
                                  <TableRow className="dataCollapsHere">
                                    <TableCell
                                      className="fw-bold text-blue-color"
                                      style={{
                                        paddingLeft: "20px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      Address
                                    </TableCell>
                                    <TableCell
                                      className="text-center fw-bold text-blue-color"
                                      style={{ fontSize: "16px" }}
                                    >
                                      City
                                    </TableCell>
                                    <TableCell
                                      className="text-center fw-bold text-blue-color"
                                      style={{ fontSize: "16px" }}
                                    >
                                      State
                                    </TableCell>
                                    <TableCell
                                      className="text-center fw-bold text-blue-color"
                                      style={{ fontSize: "16px" }}
                                    >
                                      Country
                                    </TableCell>
                                    <TableCell
                                      className="text-center fw-bold text-blue-color"
                                      style={{ fontSize: "16px" }}
                                    >
                                      Zip
                                    </TableCell>
                                    {companyName && (
                                      <TableCell
                                        className="fw-bold text-blue-color"
                                        style={{
                                          textAlign: "center",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Actions
                                      </TableCell>
                                    )}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data?.location?.map((property, index) => (
                                    <TableRow
                                      style={{
                                        cursor: "pointer",
                                        width: "318px",
                                      }}
                                      key={index}
                                      onClick={() => {
                                        if (companyName) {
                                          navigate(
                                            `/${companyName}/property-details`,
                                            {
                                              state: {
                                                id: property.LocationId,
                                                navigats: [
                                                  ...location.state.navigats,
                                                  "/property-details",
                                                ],
                                              },
                                            }
                                          );
                                        } else {
                                          navigate(
                                            `/staff-member/worker-property-details`,
                                            {
                                              state: {
                                                id: property.LocationId,
                                                navigats: [
                                                  ...location.state.navigats,
                                                  "/worker-property-details",
                                                ],
                                              },
                                            }
                                          );
                                        }
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          paddingLeft: "20px",
                                        }}
                                      >
                                        <MyLocationIcon
                                          className="text-blue-color"
                                          style={{ marginRight: "15px" }}
                                        />
                                        <Typography
                                          className="text-blue-color"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {property?.Address || "N/A"}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        className="text-center text-blue-color"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {property.City || "N/A"}
                                      </TableCell>
                                      <TableCell
                                        className="text-center text-blue-color"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {property.State || "N/A"}
                                      </TableCell>
                                      <TableCell
                                        className="text-center text-blue-color"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {property.Country || "N/A"}
                                      </TableCell>
                                      <TableCell
                                        className="text-center text-blue-color"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {property.Zip || "N/A"}
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          whiteSpace: "nowrap",
                                          textAlign: "center",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <EditIcon
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setOpen({
                                              isOpen: true,
                                              propertyData: property,
                                            });
                                          }}
                                        />
                                        <DeleteIcone
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handlePropertyDelete(
                                              property.LocationId
                                            );
                                          }}
                                          className="customerEditImgToEdit"
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </CardBody>
                          </Grid>
                        </Col>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPane>
              </TabContent>
              <TabContent
                activeTab={activeTabIdMain}
                className="text-start"
                style={{ padding: "1px 1px 0px 0px" }}
              >
                <TabPane tabId={2} className="p-0">
                  <Grid style={{ display: "flex", justifyContent: "end" }}>
                    <BlueButton
                      className="bg-button-blue-color mb-3"
                      style={
                        {
                          // padding: "3px 10px 3px 10px",
                          // fontSize: "14px",
                        }
                      }
                      onClick={() =>
                        setPaymentOpen({ isOpen: true, propertyData: null })
                      }
                      label="Add Recurring Charge"
                    />
                  </Grid>

                  <Grid>
                    <Card
                      className="border-blue-color"
                      style={{
                        borderRadius: "20px",
                        border: "1px solid",
                        padding: 0,
                      }}
                    >
                      <CardHeader
                        className="d-flex justify-content-between align-items-center table-header border-blue-color bg-blue-color customerList_searchbar customersAddCustomers"
                        style={{
                          borderBottom: "1px solid",
                          borderTopLeftRadius: "15px",
                          borderTopRightRadius: "15px",
                        }}
                      >
                        <Typography className="quot text-light customerList_head heading-five tableNameHead fw-medium">
                          Recuring Charges
                        </Typography>
                        <Grid className=" customersearch d-flex customer_searchBar searchBarOfTable">
                          <JobberSearch
                            search={search}
                            setSearch={setSearch}
                            style={{
                              background: "transparant",
                              color: "white",
                            }}
                          />
                        </Grid>
                      </CardHeader>
                      {loader ? (
                        <Grid
                          className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5"
                          style={{
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <LoaderComponent
                            height="50"
                            width="50"
                            loader={loader}
                          />
                        </Grid>
                      ) : (
                        <CardBody style={{ padding: "10px 0px" }}>
                          <JobberTable
                            style={{ whiteSpace: "nowrap" }}
                            headerData={[
                              "Sr No.",
                              "Cycle",
                              "Account",
                              "Amount",
                              "Description",
                              "CreateAt",
                              "Next Due Date",
                            ]}
                            cellData={cellData}
                            isCollapse={false}
                            page={page}
                            isNavigate={true}
                            navigatePath={
                              companyName
                                ? `/${companyName}/customerdetails`
                                : `/staff-member/ClientDetails`
                            }
                          />
                        </CardBody>
                      )}
                      <CardFooter
                        className="bg-orange-color border-blue-color text-blue-color "
                        style={{
                          borderTop: "1px solid",
                          borderBottomLeftRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }}
                      >
                        <JobberPagination
                          totalData={countData}
                          currentData={rowsPerPage}
                          dataPerPage={rowsPerPage}
                          pageItems={[10, 25, 50]}
                          page={page}
                          setPage={setPage}
                          setRowsPerPage={setRowsPerPage}
                        />
                      </CardFooter>
                    </Card>
                  </Grid>
                </TabPane>
              </TabContent>
              <TabContent
                activeTab={activeTabIdMain}
                className="text-start"
                style={{ padding: "1px 1px 0px 0px" }}
              >
                <TabPane tabId={3} className="p-0">
                  <Grid>
                    <Card className="col-6 border-blue-color customerSevenFlex">
                      <CardHeader
                        className="text-white-color bg-orange-color "
                        style={{ fontWeight: 700 }}
                      >
                        Recurring Payment
                      </CardHeader>
                      <CardBody>
                        <Grid className="text-blue-color d-flex justify-content-between">
                          <Typography style={{ fontWeight: 600 }}>
                            Balance :{" "}
                          </Typography>
                          <Typography>${balanceData} </Typography>
                        </Grid>
                      </CardBody>
                      <CardFooter className="align-items-center">
                        <Grid className="text-blue-color d-flex justify-content-between recurringPaymentBtn">
                          {/* <WhiteButton
                        style={{
                          padding: "3px 10px 3px 10px",
                          fontSize: "14px",
                        }}
                        // onClick={() =>
                        //   setPaymentOpen({ isOpen: true, propertyData: null })
                        // }
                        label="Make Payment"
                      /> */}
                          <Button
                            className="fontstylerentmodal text-blue-color fontfamilysty backgroundwhitesty configureReccuringBtnRight"
                            onClick={() => cardToggle()}
                            style={{
                              border: "1px solid #152B51",
                              marginRight: "10px",
                              fontSize: "14px",
                              fontWeight: "500",
                              background: "transparent",
                            }}
                          >
                            Configure Recurring Payment{" "}
                            {customersDetails?.some(
                              (item) => item?.IsRecurring
                            ) ? (
                              <VerifiedOutlinedIcon
                                color="success"
                                className="trueIconBtn"
                              />
                            ) : (
                              ""
                            )}
                          </Button>
                          {/* <WhiteButton
                        className="configureReccuringBtnRight"
                        style={{
                          padding: "3px 10px 3px 10px",
                          fontSize: "14px",
                        }}
                        // onClick={() =>
                        //   setConfigOpen({ isOpen: true, propertyData: null })
                        // }
                        // onClick={() => setConfigOpen()}

                        label="Make Payment"
                        // onClick={() => {
                        //   if (companyName) {
                        //     navigate(`/${companyName}/make-payment`, {
                        //       state: {
                        //         navigats: [
                        //           ...location?.state?.navigats,
                        //           "/make-payment",
                        //         ],
                        //       },
                        //     });
                        //   } else {
                        //     navigate(`/staff-member/make-payment`, {
                        //       state: {
                        //         navigats: [
                        //           ...location?.state?.navigats,
                        //           "/make-payment",
                        //         ],
                        //       },
                        //     });
                        //   }
                        // }}
                      /> */}
                        </Grid>
                      </CardFooter>
                    </Card>
                  </Grid>
                </TabPane>
              </TabContent>
              <TabContent
                activeTab={activeTabIdMain}
                className="text-start"
                style={{ padding: "1px 1px 0px 0px" }}
              >
                <TabPane tabId={4} className="p-0">
                  <Grid
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: "8px",
                      border: "0.5px solid ",
                    }}
                    className="my-4 p-2 border-blue-color"
                  >
                    <CardHeader
                      className="d-flex justify-content-between align-items-center Typography-2"
                      style={{ border: "none" }}
                    >
                      <Typography
                        className="text-blue-color heading-five"
                        style={{ fontWeight: 600 }}
                      >
                        Overview
                      </Typography>

                      <UncontrolledDropdown style={{ zIndex: "9" }}>
                        <DropdownToggle
                          className="bg-button-blue-color"
                          caret
                          color="dark"
                          style={{
                            padding: "3px 10px 3px 10px",
                            fontSize: "14px",
                            border: "none",
                          }}
                        >
                          + Add
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            className="dropdown-items text-blue-color"
                            onClick={() => {
                              if (companyName) {
                                navigate(`/${companyName}/add-quotes`, {
                                  state: {
                                    Customer: data,
                                    CustomerId: data?.CustomerId,
                                    navigats: [
                                      ...location?.state?.navigats,
                                      "/add-quotes",
                                    ],
                                  },
                                });
                              } else {
                                navigate(`/staff-member/add-quotes`, {
                                  state: {
                                    Customer: data,
                                    CustomerId: data?.CustomerId,
                                    navigats: [
                                      ...location?.state?.navigats,
                                      "/add-quotes",
                                    ],
                                  },
                                });
                              }
                            }}
                          >
                            <RequestQuoteOutlinedIcon className="icones-dropdown text-blue-color " />
                            Quote
                          </DropdownItem>
                          <DropdownItem
                            className="dropdown-items text-blue-color"
                            onClick={() => {
                              if (companyName) {
                                navigate(`/${companyName}/add-contract`, {
                                  state: {
                                    Customer: data,
                                    CustomerId: data?.CustomerId,
                                    navigats: [
                                      ...location?.state?.navigats,
                                      "/add-contract",
                                    ],
                                  },
                                });
                              } else {
                                navigate(`/staff-member/add-contract`, {
                                  state: {
                                    Customer: data,
                                    CustomerId: data?.CustomerId,
                                    navigats: [
                                      ...location?.state?.navigats,
                                      "/add-contract",
                                    ],
                                  },
                                });
                              }
                            }}
                          >
                            <WorkOutlineOutlinedIcon className="icones-dropdown text-blue-color" />
                            Contract
                          </DropdownItem>
                          <DropdownItem
                            className="dropdown-items text-blue-color"
                            onClick={() => {
                              if (companyName) {
                                navigate(`/${companyName}/invoice`, {
                                  state: {
                                    navigats: [
                                      ...location?.state?.navigats,
                                      "/invoice",
                                    ],
                                  },
                                });
                              } else {
                                navigate(`/staff-member/workerinvoice`, {
                                  state: {
                                    navigats: [
                                      ...location?.state?.navigats,
                                      "/workerinvoice",
                                    ],
                                  },
                                });
                              }
                            }}
                          >
                            <FileCopyOutlinedIcon className="icones-dropdown text-blue-color" />
                            Invoice
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </CardHeader>
                    <CardBody
                      style={{
                        padding: "10px 0px",
                        maxHeight: "400px",
                        overflowY: "scroll",
                        scrollbarWidth: "thin",
                      }}
                    >
                      <Grid className="text-start customer_navbar">
                        <Nav
                          className="bg-orange-color"
                          tabs
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            minWidth: "668px",
                            overflowX: "auto",
                          }}
                        >
                          <NavItem>
                            <NavLink
                              className={activeTabId === 1 ? "active" : ""}
                              onClick={() => handleClick(1)}
                              style={{ cursor: "pointer" }}
                            >
                              All
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={activeTabId === 3 ? "active" : ""}
                              onClick={() => handleClick(3)}
                              style={{ cursor: "pointer" }}
                            >
                              Quotes
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={activeTabId === 4 ? "active" : ""}
                              onClick={() => handleClick(4)}
                              style={{ cursor: "pointer" }}
                            >
                              Contract
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={activeTabId === 5 ? "active" : ""}
                              onClick={() => handleClick(5)}
                              style={{ cursor: "pointer" }}
                            >
                              Invoices
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent
                          activeTab={activeTabId}
                          className="text-start"
                        >
                          <TabPane tabId={1}>
                            <Row>
                              <Col
                                sm="12"
                                className="d-flex flex-column"
                                style={{
                                  gap: "10px",
                                  maxHeight:
                                    quotes?.length +
                                      invoice?.length +
                                      contract?.length >
                                    3
                                      ? "300px"
                                      : "auto",
                                  overflowY:
                                    quotes?.length +
                                      invoice?.length +
                                      contract?.length >
                                    3
                                      ? "auto"
                                      : "visible",
                                  scrollbarWidth: "thin",
                                  minWidth: "700px",
                                  overflowX: "auto",
                                }}
                              >
                                {(quotes && quotes?.length > 0) ||
                                (contract && contract?.length > 0) ||
                                (invoice && invoice?.length > 0) ? (
                                  <>
                                    {quotes &&
                                      quotes?.length > 0 &&
                                      quotes.map((data, index) => (
                                        <Grid
                                          key={index}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleQuoteNavigate(data?.QuoteId)
                                          }
                                        >
                                          <Grid className="container scrollForOverview">
                                            <Row
                                              className="row align-items-start pt-2 p-1 border-blue-color text-blue-color"
                                              style={{
                                                border: "1px solid",
                                                fontSize: "12px",
                                                boxShadow:
                                                  "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                borderRadius: "8px",
                                                border:
                                                  "0.5px solid rgba(6, 49, 100, 0.8)",
                                                color: "#063164",
                                                width: "100%",
                                              }}
                                            >
                                              <Col className="col">
                                                <Typography
                                                  style={{
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  <Typography
                                                    style={{
                                                      fontWeight: "bold",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    Quote #{data?.QuoteNumber}
                                                  </Typography>
                                                </Typography>
                                                <Typography
                                                  style={{
                                                    color:
                                                      data?.status ===
                                                      "Approved"
                                                        ? "rgb(88, 204, 88)"
                                                        : data?.status ===
                                                          "Awaiting Response"
                                                        ? "orange"
                                                        : data?.status ===
                                                          "Request changed"
                                                        ? "#FF33C6"
                                                        : "#063164",
                                                  }}
                                                >
                                                  <Typography
                                                    style={{
                                                      fontWeight: "bold",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {data?.status || "N/A"}
                                                  </Typography>
                                                </Typography>
                                              </Col>
                                              <Col className="col text-center">
                                                <Typography
                                                  className="bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "800",
                                                  }}
                                                >
                                                  TITLE
                                                </Typography>
                                                <Typography
                                                  className="text-bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {data?.Title || "N/A"}
                                                </Typography>
                                              </Col>
                                              <Col className="col text-center">
                                                <Typography
                                                  className="bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "800",
                                                  }}
                                                >
                                                  ADDRESS
                                                </Typography>
                                                <Typography
                                                  className="text-bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {data?.location?.Address ||
                                                    "-"}
                                                  ,{" "}
                                                  {data?.location?.City || "-"},{" "}
                                                  {data?.location?.State || "-"}
                                                </Typography>
                                              </Col>
                                              <Col className="col text-right">
                                                <Typography
                                                  className="bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "800",
                                                  }}
                                                >
                                                  CREATED ON
                                                </Typography>
                                                <Typography
                                                  className="text-bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {moment(
                                                    data?.createdAt
                                                  ).format(dateFormat)}
                                                </Typography>
                                              </Col>
                                            </Row>
                                          </Grid>
                                        </Grid>
                                      ))}
                                    {contract &&
                                      contract?.length > 0 &&
                                      contract?.map((data, index) => (
                                        <Grid
                                          key={index}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleContractNavigate(
                                              data?.ContractId
                                            )
                                          }
                                        >
                                          <Grid className="container scrollForOverview">
                                            <Row
                                              className="row align-items-start pt-2  p-1 outline-button-blue-color text-blue-color"
                                              style={{
                                                border: "1px solid ",
                                                fontSize: "12px",
                                                boxShadow:
                                                  "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                borderRadius: "8px",
                                                border:
                                                  "0.5px solid rgba(6, 49, 100, 0.8)",
                                                color: "#063164",
                                                width: "100%",
                                              }}
                                            >
                                              <Col className="col">
                                                <Typography
                                                  style={{
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  <Typography
                                                    style={{
                                                      fontWeight: "bold",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    Contract #
                                                    {data?.ContractNumber ||
                                                      "N/A"}
                                                  </Typography>
                                                </Typography>
                                                <Typography
                                                  style={{
                                                    color:
                                                      data?.Status ===
                                                      "Unscheduled"
                                                        ? "#E88C44"
                                                        : data?.Status ===
                                                          "Today"
                                                        ? "#089F57"
                                                        : data?.Status ===
                                                          "Upcoming"
                                                        ? "#089F57"
                                                        : data?.Status ===
                                                          "Scheduled"
                                                        ? "#C8CC00"
                                                        : "",
                                                  }}
                                                >
                                                  <Typography
                                                    style={{
                                                      fontWeight: "bold",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    {data?.Status || "N/A"}
                                                  </Typography>
                                                </Typography>
                                              </Col>
                                              <Col className="col text-center">
                                                <Typography
                                                  className="bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "800",
                                                  }}
                                                >
                                                  TITLE
                                                </Typography>
                                                <Typography
                                                  className="text-bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {data?.Title || "N/A"}
                                                </Typography>
                                              </Col>
                                              <Col className="col text-center">
                                                <Typography
                                                  className="bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "800",
                                                  }}
                                                >
                                                  ADDRESS
                                                </Typography>
                                                <Typography
                                                  className="text-bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {data?.location?.Address ||
                                                    "-"}
                                                  ,{" "}
                                                  {data?.location?.City || "-"},{" "}
                                                  {data?.location?.State || "-"}
                                                </Typography>
                                              </Col>
                                              <Col className="col text-right">
                                                <Typography
                                                  className="bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "800",
                                                  }}
                                                >
                                                  CREATED ON
                                                </Typography>
                                                <Typography
                                                  className="text-bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {moment(
                                                    data?.createdAt
                                                  ).format("ll")}
                                                </Typography>
                                              </Col>
                                            </Row>
                                          </Grid>
                                        </Grid>
                                      ))}
                                    {invoice &&
                                      invoice.length > 0 &&
                                      invoice.map((data, index) => (
                                        <Grid
                                          key={index}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleInvoiceNavigate(
                                              data?.InvoiceId
                                            )
                                          }
                                        >
                                          <Grid className="container scrollForOverview">
                                            <Row
                                              className="row align-items-start pt-2 p-1 outline-button-blue-color text-blue-color"
                                              style={{
                                                border: "1px solid ",
                                                fontSize: "12px",
                                                boxShadow:
                                                  "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                borderRadius: "8px",
                                                border:
                                                  "0.5px solid rgba(6, 49, 100, 0.8)",
                                                color: "#063164",
                                                width: "100%",
                                              }}
                                            >
                                              <Col className="col">
                                                <Typography
                                                  style={{
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  <Typography
                                                    style={{
                                                      fontWeight: "bold",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    Invoice #
                                                    {data?.InvoiceNumber}
                                                  </Typography>
                                                </Typography>
                                                <Typography
                                                  style={{
                                                    color:
                                                      data?.status ===
                                                      "Approved"
                                                        ? "rgb(88, 204, 88)"
                                                        : data?.status ===
                                                          "Awaiting Response"
                                                        ? "orange"
                                                        : data?.status ===
                                                          "Request changed"
                                                        ? "#FF33C6"
                                                        : "#063164",
                                                  }}
                                                >
                                                  <Typography
                                                    style={{
                                                      fontWeight: "bold",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {data?.Status || "N/A"}
                                                  </Typography>
                                                </Typography>
                                              </Col>
                                              <Col className="col text-center">
                                                <Typography
                                                  className="bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "800",
                                                  }}
                                                >
                                                  TITLE
                                                </Typography>
                                                <Typography
                                                  className="text-bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {data?.Subject || "N/A"}
                                                </Typography>
                                              </Col>
                                              <Col className="col text-center">
                                                <Typography
                                                  className="bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "800",
                                                  }}
                                                >
                                                  ADDRESS
                                                </Typography>
                                                <Typography
                                                  className="text-bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {data?.location?.Address ||
                                                    "-"}
                                                  ,{" "}
                                                  {data?.location?.City || "-"},{" "}
                                                  {data?.location?.State || "-"}
                                                </Typography>
                                              </Col>
                                              <Col className="col text-right">
                                                <Typography
                                                  className="bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "800",
                                                  }}
                                                >
                                                  CREATED ON
                                                </Typography>
                                                <Typography
                                                  className="text-bolt"
                                                  style={{
                                                    marginBottom: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {moment(
                                                    data?.createdAt
                                                  ).format("ll")}
                                                </Typography>
                                              </Col>
                                            </Row>
                                          </Grid>
                                        </Grid>
                                      ))}
                                  </>
                                ) : (
                                  <Col sm="12" className="d-flex">
                                    <Grid
                                      style={{
                                        backgroundColor:
                                          "rgba(6, 49, 100, 30%)",
                                        padding: "15px",
                                        borderRadius: "50%",
                                        height: "50px",
                                        width: "50px",
                                      }}
                                    >
                                      <img src={truck} alt="Truck Icon" />
                                    </Grid>
                                    <Grid className="mx-2">
                                      <Typography
                                        className="mb-0 my-2 text-blue-color"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        All
                                      </Typography>
                                      <Typography
                                        className="text-blue-color"
                                        style={{ fontSize: "12px" }}
                                      >
                                        No active Work, Quotes, Contracts and
                                        Invoices this Customer yet
                                      </Typography>
                                    </Grid>
                                  </Col>
                                )}
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId={3}>
                            <Row>
                              <Col
                                sm="12"
                                className="d-flex flex-column"
                                style={{
                                  gap: "10px",
                                  maxHeight:
                                    quotes?.length > 3 ? "300px" : "auto",
                                  overflowY:
                                    quotes?.length > 3 ? "auto" : "visible",
                                  overflowX: "auto",
                                  minWidth: "668px",
                                  overflowX: "auto",
                                }}
                              >
                                {quotes?.length > 0 ? (
                                  quotes.map((data, index) => (
                                    <Grid
                                      key={index}
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleQuoteNavigate(data?.QuoteId)
                                      }
                                    >
                                      <Grid className="container">
                                        <Grid
                                          className="row align-items-start pt-2 p-1 text-blue-color border-blue-color customerScrollHorizontal "
                                          style={{
                                            border: "1px solid ",
                                            fontSize: "12px",
                                            boxShadow:
                                              "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                            borderRadius: "8px",
                                            border:
                                              "0.5px solid rgba(6, 49, 100, 0.8)",
                                          }}
                                        >
                                          <Col className="col">
                                            <Typography
                                              style={{
                                                marginBottom: "5px",
                                              }}
                                            >
                                              <Typography
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                Quote #
                                                {data?.QuoteNumber || "N/A"}
                                              </Typography>
                                            </Typography>
                                            <Typography
                                              style={{
                                                color:
                                                  data?.status === "Approved"
                                                    ? "rgb(88, 204, 88)"
                                                    : data?.status ===
                                                      "Awaiting Response"
                                                    ? "orange"
                                                    : data?.status ===
                                                      "Request changed"
                                                    ? "#FF33C6"
                                                    : "#063164",
                                              }}
                                            >
                                              <Typography
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {" "}
                                                {data?.status || "N/A"}
                                              </Typography>
                                            </Typography>
                                          </Col>
                                          <Col className="col text-center">
                                            <Typography
                                              className="bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "800",
                                              }}
                                            >
                                              TITLE
                                            </Typography>
                                            <Typography
                                              className="text-bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {data?.Title || "N/A"}
                                            </Typography>
                                          </Col>
                                          <Col className="col text-center">
                                            <Typography
                                              className="bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "800",
                                              }}
                                            >
                                              ADDRESS
                                            </Typography>
                                            <Typography
                                              className="text-bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {data?.location?.Address || "-"},{" "}
                                              {data?.location?.City || "-"},{" "}
                                              {data?.location?.State || "-"}
                                            </Typography>
                                          </Col>
                                          <Col className="col text-right">
                                            <Typography
                                              className="bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "800",
                                              }}
                                            >
                                              CREATED ON
                                            </Typography>
                                            <Typography
                                              className="text-bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {moment(data.createdAt).format(
                                                "ll"
                                              )}
                                            </Typography>
                                          </Col>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ))
                                ) : (
                                  <Col sm="12" className="d-flex">
                                    <Grid
                                      style={{
                                        backgroundColor:
                                          "rgba(6, 49, 100, 30%)",
                                        padding: "15px",
                                        borderRadius: "50%",
                                        height: "50px",
                                        width: "50px",
                                      }}
                                    >
                                      <img src={truck} alt="Truck Icon" />
                                    </Grid>
                                    <Grid className="mx-2">
                                      <Typography
                                        className="mb-0 my-2 text-blue-color"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        Quotes
                                      </Typography>
                                      <Typography
                                        className="text-blue-color"
                                        style={{ fontSize: "12px" }}
                                      >
                                        No active quotes for this Customer yet
                                      </Typography>
                                    </Grid>
                                  </Col>
                                )}
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId={4}>
                            <Row>
                              <Col
                                sm="12"
                                className="d-flex flex-column"
                                style={{
                                  gap: "10px",
                                  maxHeight:
                                    contract?.length > 3 ? "300px" : "auto",
                                  overflowY:
                                    contract?.length > 3 ? "auto" : "visible",
                                  overflowX: "auto",
                                  minWidth: "668px",
                                  overflowX: "auto",
                                }}
                              >
                                {contract && contract?.length > 0 ? (
                                  contract.map((data, index) => (
                                    <Grid
                                      key={index}
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleContractNavigate(data?.ContractId)
                                      }
                                    >
                                      <Grid className="container">
                                        <Row
                                          className="row align-items-start pt-2 p-1 outline-button-blue-color text-blue-color customerScrollHorizontal "
                                          style={{
                                            border: "1px solid ",
                                            fontSize: "12px",
                                            boxShadow:
                                              "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                            borderRadius: "8px",
                                            border:
                                              "0.5px solid rgba(6, 49, 100, 0.8)",
                                          }}
                                        >
                                          <Col className="col">
                                            <Typography
                                              style={{
                                                marginBottom: "5px",
                                              }}
                                            >
                                              <Typography
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                Contract #{data?.ContractNumber}
                                              </Typography>
                                            </Typography>
                                            <Typography
                                              style={{
                                                color:
                                                  data?.Status === "Unscheduled"
                                                    ? "#E88C44"
                                                    : data?.Status === "Today"
                                                    ? "#089F57"
                                                    : data?.Status ===
                                                      "Upcoming"
                                                    ? "#089F57"
                                                    : data?.Status ===
                                                      "Scheduled"
                                                    ? "#C8CC00"
                                                    : "",
                                              }}
                                            >
                                              <Typography
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {data?.Status}
                                              </Typography>
                                            </Typography>
                                          </Col>
                                          <Col className="col text-center">
                                            <Typography
                                              className="bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "800",
                                              }}
                                            >
                                              TITLE
                                            </Typography>
                                            <Typography
                                              className="text-bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {data?.Title || "N/A"}
                                            </Typography>
                                          </Col>
                                          <Col className="col text-center">
                                            <Typography
                                              className="bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "800",
                                              }}
                                            >
                                              ADDRESS
                                            </Typography>
                                            <Typography
                                              className="text-bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {data?.location?.Address || "-"},{" "}
                                              {data?.location?.City || "-"},{" "}
                                              {data?.location?.State || "-"}
                                            </Typography>
                                          </Col>
                                          <Col className="col text-right">
                                            <Typography
                                              className="bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "800",
                                              }}
                                            >
                                              CREATED ON
                                            </Typography>
                                            <Typography
                                              className="text-bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {moment(data?.createdAt).format(
                                                "ll"
                                              )}
                                            </Typography>
                                          </Col>
                                        </Row>
                                      </Grid>
                                    </Grid>
                                  ))
                                ) : (
                                  <Col sm="12" className="d-flex">
                                    <Grid
                                      style={{
                                        backgroundColor:
                                          "rgba(6, 49, 100, 30%)",
                                        padding: "15px",
                                        borderRadius: "50%",
                                        height: "50px",
                                        width: "50px",
                                      }}
                                    >
                                      <img src={truck} alt="Truck Icon" />
                                    </Grid>
                                    <Grid className="mx-2">
                                      <Typography
                                        className="mb-0 my-2 text-blue-color"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        Contract
                                      </Typography>
                                      <Typography
                                        className="text-blue-color"
                                        style={{ fontSize: "12px" }}
                                      >
                                        No active Contracts for this Customer
                                        yet
                                      </Typography>
                                    </Grid>
                                  </Col>
                                )}
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId={5}>
                            <Row>
                              <Col
                                sm="12"
                                className="d-flex flex-column"
                                style={{
                                  gap: "10px",
                                  maxHeight:
                                    invoice?.length > 3 ? "300px" : "auto",
                                  overflowY:
                                    invoice?.length > 3 ? "auto" : "visible",
                                  overflowX: "auto",
                                  minWidth: "668px",
                                  overflowX: "auto",
                                }}
                              >
                                {invoice && invoice.length > 0 ? (
                                  invoice.map((data, index) => (
                                    <Grid
                                      key={index}
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleInvoiceNavigate(data?.InvoiceId)
                                      }
                                    >
                                      <Grid className="container">
                                        <Row
                                          className="row align-items-start pt-2  p-1 outline-button-blue-color text-blue-color customerScrollHorizontal "
                                          style={{
                                            border: "1px solid ",
                                            fontSize: "12px",
                                            boxShadow:
                                              "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                            borderRadius: "8px",
                                            border:
                                              "0.5px solid rgba(6, 49, 100, 0.8)",
                                            overflowX: "auto",
                                          }}
                                        >
                                          <Col className="col">
                                            <Typography
                                              style={{
                                                marginBottom: "5px",
                                              }}
                                            >
                                              <Typography
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                Invoice #
                                                {data?.InvoiceNumber || "N/A"}
                                              </Typography>
                                            </Typography>
                                            <Typography
                                              style={{
                                                color:
                                                  data?.status === "Approved"
                                                    ? "rgb(88, 204, 88)"
                                                    : data?.status ===
                                                      "Awaiting Response"
                                                    ? "orange"
                                                    : data?.status ===
                                                      "Request changed"
                                                    ? "#FF33C6"
                                                    : "#063164",
                                              }}
                                            >
                                              <Typography
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {" "}
                                                {data?.Status || "N/A"}
                                              </Typography>
                                            </Typography>
                                          </Col>
                                          <Col className="col text-center">
                                            <Typography
                                              className="bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "800",
                                              }}
                                            >
                                              Subject
                                            </Typography>
                                            <Typography
                                              className="text-bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {data?.Subject || "N/A"}
                                            </Typography>
                                          </Col>
                                          <Col className="col text-center">
                                            <Typography
                                              className="bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "800",
                                              }}
                                            >
                                              ADDRESS
                                            </Typography>
                                            <Typography
                                              className="text-bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {data?.location?.Address || "-"},{" "}
                                              {data?.location?.City || "-"},{" "}
                                              {data?.location?.State || "-"}
                                            </Typography>
                                          </Col>
                                          <Col className="col text-right">
                                            <Typography
                                              className="bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "800",
                                              }}
                                            >
                                              CREATED ON
                                            </Typography>
                                            <Typography
                                              className="text-bolt"
                                              style={{
                                                marginBottom: "5px",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {moment(data?.createdAt).format(
                                                "ll"
                                              )}
                                            </Typography>
                                          </Col>
                                        </Row>
                                      </Grid>
                                    </Grid>
                                  ))
                                ) : (
                                  <Col sm="12" className="d-flex">
                                    <Grid
                                      style={{
                                        backgroundColor:
                                          "rgba(6, 49, 100, 30%)",
                                        padding: "15px",
                                        borderRadius: "50%",
                                        height: "50px",
                                        width: "50px",
                                      }}
                                    >
                                      <img src={truck} alt="Truck Icon" />
                                    </Grid>
                                    <Grid className="mx-2">
                                      <Typography
                                        className="mb-0 my-2 text-blue-color"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        Invoice
                                      </Typography>
                                      <Typography
                                        className="text-blue-color"
                                        style={{ fontSize: "12px" }}
                                      >
                                        No active Invoices this Customer yet
                                      </Typography>
                                    </Grid>
                                  </Col>
                                )}
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </Grid>
                    </CardBody>
                  </Grid>
                </TabPane>
              </TabContent>
              <TabContent
                activeTab={activeTabIdMain}
                className="text-start"
                style={{ padding: "1px 1px 0px 0px" }}
              >
                <TabPane tabId={5} className="p-0">
                  <Col
                    md={12}
                    className="first-card contact_info_histort"
                    style={{ paddingLeft: "15px" }}
                  >
                    <Grid
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        borderRadius: "8px",
                        border: "0.5px solid ",
                        // width: "94%",
                      }}
                      className=" border-blue-color"
                    >
                      <CardHeader
                        className="d-flex justify-content-between align-items-center p-1 Typography-2"
                        style={{ border: "none" }}
                      >
                        <Typography
                          className="text-blue-color billing_history heading-five"
                          style={{
                            fontWeight: 600,
                            fontSize: "26px",
                            paddingLeft: "12px",
                          }}
                        >
                          Billing History
                        </Typography>
                      </CardHeader>

                      <CardBody
                        style={{
                          padding: "10px 0px",
                          maxHeight: "300px",
                          overflowY: "auto",
                          scrollbarWidth: "thin",
                        }}
                        className="d-flex flex-column mx-3"
                      >
                        {data?.invoiceData &&
                        data?.invoiceData?.length > 0 &&
                        data?.invoiceData[0]?.InvoiceNumber ? (
                          data.invoiceData?.map((item, index) => (
                            <Grid className="d-flex flex-column">
                              {item?.payments &&
                                item?.payments?.length > 0 &&
                                item?.payments?.map((elem, i) => (
                                  <Grid className="d-flex justify-content-between">
                                    <Grid className="d-flex">
                                      <Grid
                                        style={{
                                          backgroundColor:
                                            "rgba(6, 49, 100, 10%)",
                                          borderRadius: "50%",
                                          height: "50px",
                                          width: "50px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <img src={PaymentBlue} />
                                      </Grid>
                                      <Grid className="mx-2">
                                        <Typography
                                          className="mb-0 my-2 text-blue-color"
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: 600,
                                          }}
                                        >
                                          {/* {item?.IssueDate} */}
                                          {moment(item?.IssueDate).format(
                                            dateFormat
                                          )}
                                        </Typography>
                                        <Typography
                                          className="text-blue-color"
                                          style={{ fontSize: "14px" }}
                                        >
                                          Payment for invoice #
                                          {item?.InvoiceNumber || "N/A"}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid className="d-flex align-items-end">
                                      <Typography
                                        className="text-blue-color"
                                        style={{ fontSize: "14px" }}
                                      >
                                        -${elem?.amount || "N/A"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ))}
                              <Grid className="d-flex justify-content-between">
                                <Grid className="d-flex">
                                  <Grid
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 10%)",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img src={InvoiceBlue} />
                                  </Grid>
                                  <Grid className="mx-2">
                                    <Typography
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {/* {item?.IssueDate || "N/A"} */}
                                      {moment(item?.IssueDate).format(
                                        dateFormat
                                      ) || "N/A"}
                                    </Typography>
                                    <Typography
                                      className="text-blue-color"
                                      style={{ fontSize: "14px" }}
                                    >
                                      Invoice #{item?.InvoiceNumber || "N/A"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid className="d-flex align-items-end">
                                  <Typography
                                    className="text-blue-color"
                                    style={{ fontSize: "14px" }}
                                  >
                                    +${item?.Total || "N/A"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))
                        ) : (
                          <Typography>No billing history</Typography>
                        )}
                      </CardBody>

                      <CardFooter
                        className="d-flex border-blue-color justify-content-between bg-orange-color text-white-color"
                        style={{
                          borderTop: "1px solid",
                          borderBottomLeftRadius: "8px",
                          borderBottomRightRadius: "8px",
                          alignItems: "center",
                          padding: "6px 10px 7px",
                        }}
                      >
                        <Typography>Current Balance</Typography>
                        <Typography>${data?.currentBalance}</Typography>
                      </CardFooter>
                    </Grid>
                  </Col>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Grid>
      )}
      <CustomerProperty
        open={open}
        setOpen={setOpen}
        data={data}
        getData={getData}
      />

      <Dialog
        fullWidth
        open={modelOpen}
        onClose={() => setModelOpen(false)}
        PaperProps={{
          style: {
            borderRadius: "12px",
            overflow: "hidden",
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <DialogTitle
          className="colorBlue"
          style={{
            // color: "#fff",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Please Confirm
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            padding: "30px",
            borderTop: "4px solid #e88c44",
            textAlign: "center",
          }}
        >
          <Grid container spacing={3} className="mailConfigurationBox">
            <Grid item xs={12}>
              <Typography
                className="text-blue-color"
                style={{
                  fontSize: "16px",
                  lineHeight: "1.6",
                  color: "#333",
                }}
              >
                Are you sure you want to resend the{" "}
                <strong>Customer Hub login invitation</strong> to:
                <Typography
                  component="span"
                  style={{
                    fontWeight: "bold",
                    marginLeft: "5px",
                  }}
                  className="text-orange-color"
                >
                  {data?.EmailAddress || "the customer's email"}
                </Typography>
                ?
                <br />
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#555",
                    marginTop: "10px",
                    display: "block",
                  }}
                >
                  Note: Ensure the email address is correct before proceeding.
                </Typography>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
              }}
              className="mailSendConfiguration"
            >
              {loader ? (
                <LoaderComponent loader={loader} height="30" width="30" />
              ) : (
                <>
                  <Button
                    className="text-blue-color border-blue-color nogoback"
                    style={{
                      fontSize: "14px",
                      color: "#063164",
                      border: "1px solid #063164",
                      background: "#fff",
                      textTransform: "none",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                    onClick={() => setModelOpen(false)}
                  >
                    No, Go Back
                  </Button>
                  <Button
                    className="bg-blue-color mailSendYesComonent"
                    style={{
                      fontSize: "14px",
                      color: "#fff",
                      textTransform: "none",
                      marginLeft: "15px",
                      backgroundColor: "#063164",
                      border: "none",
                      boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.15)",
                      transition: "all 0.3s ease",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#042949")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#063164")
                    }
                    disabled={loading}
                    onClick={sendMail}
                  >
                    Yes, Send Mail
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        open={paymentOpen}
        onClose={() => {
          setPaymentOpen(false);
          resetForm();
          setSelectedAccountId("");
          setSelectedFrequency("");
          setSelectedAccount("");
        }}
        addBankAccountDialogOpen={addBankAccountDialogOpen}
        setAddBankAccountDialogOpen={setAddBankAccountDialogOpen}
        accountTypeName={accountTypeName}
        fetchAccounts={fetchAccounts}
        PaperProps={{
          style: {
            borderRadius: "12px",
            overflow: "hidden",
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <DialogTitle
          className="text-blue-color"
          style={{
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Add Recurring Charge
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            padding: "30px",
            borderTop: "4px solid #e88c44",
          }}
        >
          <Grid>
            <Grid>
              <Dropdown
                className="mb-3"
                isOpen={frequencyDropdownOpen}
                toggle={toggleFrequencyDropdown}
              >
                <DropdownToggle
                  className="border-blue-color text-blue-color"
                  caret
                  style={{
                    width: "100%",
                    border: "1px solid",
                    backgroundColor: "transparent",
                    height: "50px",
                    textAlign: "start",
                  }}
                >
                  {selectedFrequency || "Select Frequency"}{" "}
                </DropdownToggle>
                <DropdownMenu
                  className="dropdownfontsyle"
                  style={{
                    width: "100%",
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                  name="frequency"
                >
                  {[
                    "Yearly",
                    "Every n Months",
                    "Monthly",
                    "Every n Weeks",
                    "Quarterly",
                  ].map((option) => (
                    <DropdownItem
                      style={{ fontSize: "14px" }}
                      className="text-blue-color"
                      key={option}
                      onClick={() => handleFrequencySelection(option)}
                    >
                      {option}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>

              {/* Conditional Input for n Months or n Weeks */}
              {(selectedFrequency === "Every n Months" ||
                selectedFrequency === "Every n Weeks") && (
                <Grid>
                  <InputText
                    className="mb-3 border-blue-color text-blue-color"
                    style={{ fontSize: "15px", height: "46px", width: "100%" }}
                    type="number"
                    name="frequency_interval"
                    value={formData.frequency_interval}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Allow empty value to reset the field
                      if (
                        value === "" ||
                        (value >= 1 &&
                          value <=
                            (selectedFrequency === "Every n Months" ? 12 : 4))
                      ) {
                        handleInputChange(e);
                      }
                    }}
                    min="1"
                    placeholder={`Enter number of ${
                      selectedFrequency === "Every n Months"
                        ? "months"
                        : "weeks"
                    }`}
                    label={`Number of ${
                      selectedFrequency === "Every n Months"
                        ? "Months"
                        : "Weeks"
                    }`}
                  />
                </Grid>
              )}
            </Grid>
            <Dropdown
              className="mb-3"
              isOpen={selectChargeDropDown}
              toggle={toggles1}
            >
              <DropdownToggle
                className="border-blue-color text-blue-color"
                caret
                style={{
                  width: "100%",
                  border: "1px solid",
                  backgroundColor: "transparent",
                  height: "50px",
                  textAlign: "start",
                }}
              >
                {selectedAccount || "Select Account"}{" "}
                {/* Display selected account */}
              </DropdownToggle>
              <DropdownMenu
                className="dropdownfontsyle"
                style={{ width: "100%", maxHeight: "200px", overflowY: "auto" }}
                name="rent_cycle"
              >
                {AssetAccounts?.length > 0 ? (
                  <>
                    <DropdownItem
                      className="fontstylerentmodal text-blue-color"
                      header
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      Asset
                    </DropdownItem>
                    {AssetAccounts?.map((item) => (
                      <DropdownItem
                        style={{ fontSize: "12px" }}
                        className="text-blue-color"
                        key={item._id}
                        onClick={() => {
                          handleAccountSelection(
                            item.account_id,
                            "Asset",
                            item.account_name
                          );
                        }}
                      >
                        {item.account_name}
                      </DropdownItem>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </DropdownMenu>
            </Dropdown>

            <Grid>
              <InputText
                value={formData.amount}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[0-9\s.]*$/.test(value)) {
                    handleInputChange(e);
                  }
                }}
                name="amount"
                placeholder="$ 0.00"
                label="Amount"
                type="number"
                className="text-blue-color w-100 mb-3 "
                fieldHeight="56px"
              />
            </Grid>
            <Grid>
              <TextField
                className="note-details mt-1 text-blue-color border-blue-color"
                name="description"
                label="Description"
                type="text"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Enter A description"
                multiline
                rows={3}
              />
            </Grid>
          </Grid>

          <Grid className="mt-3 d-flex justify-content-between chargeModelBt">
            <WhiteButton
              className="text-blue-color border-blue-color nogoback"
              onClick={() => {
                setPaymentOpen(false);

                resetForm();
                setSelectedAccountId("");
                setSelectedFrequency("");
                setSelectedAccount("");
              }}
              label="Cancel"
            />
            <BlueButton
              className="text-blue-color border-blue-color nogoback"
              onClick={handleAddButtonClick}
              label="Add"
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomerDetails;
