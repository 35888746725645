import React, { useState, useEffect } from "react";
import Edit from "../../../assets/image/icons/edit.svg";
import Delete from "../../../assets/image/icons/delete.svg";
import swal from "sweetalert";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth.jsx";
import moment from "moment";
import AxiosInstance from "../../AxiosInstance.jsx";
import Quote from "./Views/Quote.jsx";
import sendSwal from "../../../components/Swal/sendSwal.jsx";
import { DeleteIcone, EditIcon } from "../../../components/Icon/Index.jsx";
import showToast from "../../../components/Toast/Toster.jsx";
const Quotes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [quotesData, setQuotesData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [countData, setCountData] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [tokenDecode, setTokenDecode] = useState({});
  const [DateDecode, setDateDecode] = useState({});
  const [dateFormat, setDateFormat] = useState("MM-DD-YYYY");

  useEffect(() => {
    handleAuth(navigate, location);
  }, []);

  const fetchData = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
      setDateDecode(res.themes);
      getData(res.data?.companyId);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [rowsPerPage, page, search,selectedStatus]);
  useEffect(() => {
    const handleDateFormat = () => {
      if (DateDecode?.Format) {
        setDateFormat(DateDecode?.Format);
      } else {
        setDateFormat("MM-DD-YYYY");
      }
    };

    handleDateFormat();
  }, [DateDecode]);

  const handleDropdownSelect = (status) => {
    setSelectedStatus(status);
  };

  const getData = async (companyId) => {
    try {
      setLoader(true);
      const params = {
        pageSize: rowsPerPage,
        pageNumber: page,
        search: search || "",
        statusFilter: selectedStatus || "",
      };

      const res = await AxiosInstance.get(`/quote/get_quotes/${companyId}`, {
        params,
      });
      if (res?.data) {
        setQuotesData(res.data.data || []);
        setCountData(res.data.totalCount || 0);
      } else {
        console.error("No data received from the server.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handleEditClick = (id) => {
    if (companyName) {
      navigate(`/${companyName}/add-quotes`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/add-quotes"],
        },
      });
    } else {
      navigate(`/staff-member/add-quotes`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/add-quotes"],
        },
      });
    }
  };

  const handleDelete = (id) => {
    sendSwal().then(async (deleteReason) => {
      if (deleteReason) {
        try {
          const response = await AxiosInstance.delete(`/quote/${id}`, {
            data: { DeleteReason: deleteReason },
          });
          if (response?.data?.statusCode === 200) {
            setTimeout(() => {
              showToast.success(response?.data?.message);
            }, 500);
            getData(tokenDecode?.companyId);
          } else {
            showToast.warning(response?.data?.message);
          }
        } catch (error) {
          console.error("Error:", error);
          showToast.error(error?.message || "An error occurred");
        }
      } else {
        showToast.success("Quote is safe!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  const cellData = quotesData?.map((item, index) => {
    return {
      key: item.QuoteId,
      value: [
        page * rowsPerPage + index + 1,
        `${item?.customer?.FirstName || "N/A"} ${
          item?.customer?.LastName || "N/A"
        }`,
        <>
          #{item?.QuoteNumber || "N/A"}
          <br />
          {item?.Title || "N/A"}
        </>,
        `${item?.location?.Address || "N/A"} ${item?.location?.City || "N/A"} ${
          item?.location?.State || "N/A"
        } ${item?.location?.Country || "N/A"} ${item?.location?.Zip || "N/A"}`,
        moment(item.updatedAt).format(dateFormat),
        <b
          style={{
            color:
              item?.status === "Awaiting Response"
                ? "orange"
                : item?.status === "Approved"
                ? "#58cc58"
                : item?.status === "Request changed"
                ? "#FF33C6"
                : "",
          }}
        >
          {item?.status}
        </b>,
        <>{`$${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(item?.Total || 0)}`}</>,
        <>
          <EditIcon
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(item?.QuoteId);
            }}
          />
          <DeleteIcone
            className="customerEditImgToEdit"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item?.QuoteId);
            }}
          />
        </>,
      ],
    };
  });

  const collapseData = quotesData?.map((item) => ({
    createdAt: item?.createdAt || "No details provided",
  }));

  const dropdownOptions = [
    { text: "All" },
    { text: "Awaiting Response" },
    { text: "Approved" },
    { text: "Draft" },
  ];

  return (
    <>
      <Quote
        loader={loader}
        search={search}
        setSearch={setSearch}
        cellData={cellData}
        collapseData={collapseData}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        companyName={companyName}
        countData={countData}
        dropdownOptions={dropdownOptions}
        rowsPerPage={rowsPerPage}
        handleDropdownSelect={handleDropdownSelect}
      />
    </>
  );
};

export default Quotes;
