// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TempleteMOdel{
    background-color: #243a73 !important;
    color: white !important;
    font-weight: 700 !important;
}

.templeteName{
    color: #243a73 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    /* border-bottom:1px solid #e88c44 !important ;
    width: 79px !important; */
}

/* 1680 */
@media (max-width: 1700px) {}

/* 1500 */
@media (max-width: 1680px) {}

/* 1440 */
@media (max-width: 1449px) {}

/* 1280 */
@media (max-width: 1439px) {}

/* 992 */
@media (max-width: 1279px) {}

/* 768 */
@media (max-width: 991px) {}

/* 576 */
@media (max-width: 767px) {
    .templeNameHere{
        flex-direction: column !important;
    }
}

/* 525 */
@media (max-width: 575px) {
    .tepleteFiveDis{
        width: 100% !important;
    }
}

/* 480 */
@media (max-width: 524px) {}

/* 425 */
@media (max-width: 479px) {}

/* 375 */
@media (max-width: 424px) {}

/* 320 */
@media (max-width: 374px) {}

/* 280 */
@media (max-width: 319px) {}
`, "",{"version":3,"sources":["webpack://./src/Views/Admin/Communications/Views/style.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,uBAAuB;IACvB,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;IACzB,0BAA0B;IAC1B,2BAA2B;IAC3B;6BACyB;AAC7B;;AAEA,SAAS;AACT,4BAA4B;;AAE5B,SAAS;AACT,4BAA4B;;AAE5B,SAAS;AACT,4BAA4B;;AAE5B,SAAS;AACT,4BAA4B;;AAE5B,QAAQ;AACR,4BAA4B;;AAE5B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR;IACI;QACI,iCAAiC;IACrC;AACJ;;AAEA,QAAQ;AACR;IACI;QACI,sBAAsB;IAC1B;AACJ;;AAEA,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B","sourcesContent":[".TempleteMOdel{\n    background-color: #243a73 !important;\n    color: white !important;\n    font-weight: 700 !important;\n}\n\n.templeteName{\n    color: #243a73 !important;\n    font-size: 18px !important;\n    font-weight: 500 !important;\n    /* border-bottom:1px solid #e88c44 !important ;\n    width: 79px !important; */\n}\n\n/* 1680 */\n@media (max-width: 1700px) {}\n\n/* 1500 */\n@media (max-width: 1680px) {}\n\n/* 1440 */\n@media (max-width: 1449px) {}\n\n/* 1280 */\n@media (max-width: 1439px) {}\n\n/* 992 */\n@media (max-width: 1279px) {}\n\n/* 768 */\n@media (max-width: 991px) {}\n\n/* 576 */\n@media (max-width: 767px) {\n    .templeNameHere{\n        flex-direction: column !important;\n    }\n}\n\n/* 525 */\n@media (max-width: 575px) {\n    .tepleteFiveDis{\n        width: 100% !important;\n    }\n}\n\n/* 480 */\n@media (max-width: 524px) {}\n\n/* 425 */\n@media (max-width: 479px) {}\n\n/* 375 */\n@media (max-width: 424px) {}\n\n/* 320 */\n@media (max-width: 374px) {}\n\n/* 280 */\n@media (max-width: 319px) {}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
