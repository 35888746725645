import React, { useState, useEffect } from "react";
import "./style.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth.jsx";
import moment from "moment";
import AxiosInstance from "../../AxiosInstance.jsx";
import ContractView from "./views/Contract.jsx";
import sendSwal from "../../../components/Swal/sendSwal.jsx";
import { DeleteIcone, EditIcon } from "../../../components/Icon/Index.jsx";
import showToast from "../../../components/Toast/Toster.jsx";

const Contract = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customerData, setCustomerData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [countData, setCountData] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [tokenDecode, setTokenDecode] = useState(null);
  const [DateDecode, setDateDecode] = useState({});

  useEffect(() => {
    handleAuth(navigate, location);
  }, []);

  const fetchData = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
      setDateDecode(res.themes);
      getData(res.data.companyId);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [rowsPerPage, page, search,selectedStatus]);

  const [dateFormat, setDateFormat] = useState("MM-DD-YYYY");
  useEffect(() => {
    const handleDateFormat = () => {
      if (DateDecode?.Format) {
        setDateFormat(DateDecode?.Format);
      } else {
        setDateFormat("MM-DD-YYYY");
      }
    };

    handleDateFormat();
  }, [DateDecode]);

  const getData = async (companyId) => {
    setLoader(true);
    try {
      const res = await AxiosInstance.get(`/contract/${companyId}`, {
        params: {
          pageSize: rowsPerPage,
          pageNumber: page,
          search: search || "",
          statusFilter: selectedStatus || "",
        },
      });
      if (res?.data) {
        setCustomerData(res.data.data || []);
        setCountData(res.data.totalCount || 0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handleEditClick = (id) => {
    if (companyName) {
      navigate(`/${companyName}/add-contract`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/add-contract"],
        },
      });
    } else {
      navigate(`/staff-member/add-contract`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/add-contract"],
        },
      });
    }
  };

  const handleDelete = (id) => {
    sendSwal().then(async (deleteReason) => {
      if (deleteReason) {
        try {
          const response = await AxiosInstance.delete(`/contract/${id}`, {
            data: { DeleteReason: deleteReason },
          });
          if (response?.data.statusCode === 200) {
            setCustomerData((prevData) =>
              prevData.filter((item) => item?.ContractId !== id)
            );
            setTimeout(() => {
              showToast.success(response?.data?.message);
            }, 500);
            getData(tokenDecode?.companyId);
          } else {
            showToast.warning(response?.data?.message);
          }
        } catch (error) {
          console.error("Error:", error);
          showToast.error(error);
        }
      } else {
        showToast.success("Contract is safe!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  const cellData =
    customerData &&
    customerData?.length > 0 &&
    customerData?.map((item, index) => {
      const properties = item?.properties || [];
      let propertyDisplay;
      if (properties?.length === 1) {
        const property = properties[0];
        propertyDisplay = `${property?.street_address1 || "N/A"}
       ${property?.street_address2 || "N/A"}
       ${property?.street_address3 || ""} ${property?.city || "N/A"} 
       ${property?.state || "N/A"} 
      ${property?.country || "N/A"} 
      ${property?.zip_code || "N/A"}`;
      } else {
        propertyDisplay = `${properties?.length} ${
          properties.length > 1 ? "Properties" : "Property"
        }`;
      }

      return {
        key: item?.ContractId,
        value: [
          page * rowsPerPage + index + 1,
          `${item?.customer?.FirstName || "N/A"} ${
            item?.customer?.LastName || "N/A"
          }`,
          <>
            #{item?.ContractNumber || "N/A"}
            <br />
            {item?.Title || "N/A"}
          </>,
          <div
            dangerouslySetInnerHTML={{
              __html: `${item?.location?.Address || "N/A"} ${
                item?.location?.City || "N/A"
              }<br>${item?.location?.State || "N/A"} ${
                item?.location?.Country || "N/A"
              } ${item?.location?.Zip || "N/A"}`,
            }}
          />,
          item?.RecuringJob?.StartDate
            ? moment(item?.RecuringJob?.StartDate).format(dateFormat)
            : item?.OneoffJob?.StartDate
            ? moment(item?.OneoffJob?.StartDate).format(dateFormat)
            : "-",

          <b
            style={{
              color:
                item?.Status === "Unscheduled"
                  ? "#E88C44"
                  : item?.Status === "Today"
                  ? "#089F57"
                  : item?.Status === "Upcoming"
                  ? "#089F57"
                  : item?.Status === "Scheduled"
                  ? "#C8CC00"
                  : item?.Status === "Converted"
                  ? "#1f104f"
                  : "",
            }}
          >
            {item?.Status}
          </b>,
          <>{`$${new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item?.Total ?? 0)}`}</>,
          <>
            <EditIcon
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick(item?.ContractId);
              }}
            />
            <DeleteIcone
              className="customerEditImgToEdit"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(item?.ContractId);
              }}
            />
          </>,
        ],
      };
    });
  const handleDropdownSelect = (status) => {
    setSelectedStatus(status);
  };

  const dropdownOptions = [
    { text: "All" },
    { text: "Unscheduled" },
    { text: "Scheduled" },
    { text: "Converted" },
    { text: "Upcoming" },
    { text: "Today" },
  ];

  return (
    <>
      <ContractView
        loader={loader}
        search={search}
        setSearch={setSearch}
        cellData={cellData}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        companyName={companyName}
        countData={countData}
        rowsPerPage={rowsPerPage}
        dropdownOptions={dropdownOptions}
        handleDropdownSelect={handleDropdownSelect}
        dateFormat={dateFormat}
      />
    </>
  );
};

export default Contract;
