import axios from "axios";
import AxiosInstance from "./axios";
import interceptors from "./axios";

export const getTokenizationKey = async (Company_Id) => {
  try {
    const response = await AxiosInstance.get(
      `nmi/nmi_public_key/${Company_Id}`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const getTokenizationKeyCustomer = async (CustomerId) => {
  try {
    const response = await AxiosInstance.get(
      `nmi/nmi_public_key_customer/${CustomerId}`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const addCardDetails = async (payload) => {
  try {
    const response = await AxiosInstance.post(`nmi/add-customer-card`, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
// Get customer vault ID
export const getCustomerVaultId = async (CustomerId) => {
  try {
    const response = await AxiosInstance.get(
      `nmi/getCreditCards/${CustomerId}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get card details
export const getCardDetails = async (payload) => {
  try {
    const response = await AxiosInstance.post(
      `nmi/get-billing-customer-vault`,
      payload
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get recurring cards
export const getRecurringCards = async (data) => {
  try {
    const response = await AxiosInstance.post(`recurring-payment/get-cards`, data);

    return response.data;
  } catch (error) {
    if (typeof error === "string") throw error;
    else if (Object.keys(error).length) throw JSON.stringify(error);
    else throw "Network problem";
  }
};

// Add recurring cards
export const addRecurringCards = async (data) => {
  try {
    const response = await AxiosInstance.post(`recurring-payment/add-cards`, data);
    return response.data;
  } catch (error) {
    if (typeof error === "string") throw error;
    else if (Object.keys(error).length) throw JSON.stringify(error);
    else throw "Network problem";
  }
};


//-------------------------------------------------------------------------

export const getCardType = async (cc_bin, cc_type) => {
  const options = {
    method: "POST",
    url: `https://bin-ip-checker.p.rapidapi.com/?bin=${cc_bin}`,

    headers: {
      "content-type": "application/json",
      "X-RapidAPI-Key": "1bd772d3c3msh11c1022dee1c2aep1557bajsn0ac41ea04ef7",
      "X-RapidAPI-Host": "bin-ip-checker.p.rapidapi.com",
    },
  };

  try {
    const response = await axios.request(options);
    return response.data.BIN.type || cc_type;
  } catch (error) {
    return cc_type;
  }
};

// Update Card transaction type (credit/debit)
export const getRentalOwnerCardTypeSettings = async (tenantId, lease_id) => {
  try {
    const response = await interceptors.get(
      `tenant/payment_settings/${tenantId}/${lease_id}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCardLogo = async (cc_type) => {
  try {
    const res = await axios.get(
      `https://logo.clearbit.com/${cc_type.toLowerCase().replace(" ", "")}.com`
    );
    return res.config.url || "";
  } catch (error) {
    return "";
  }
};

