import React from "react";
import { Grid } from "@mui/material";
import  {Typography} from "@mui/material";
import "./style.css"

const FooterDetails = ({
  quotesData,
  contractData,
  invoicedata,
  requestData,
  options,
}) => {
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", options);
  };

  const data = quotesData || contractData || invoicedata;

  return (
    <Grid
      className="p-3 my-4 border-blue-color"
      style={{ border: "1px solid", borderRadius: "12px" }}
    >
      <Grid>
        <Grid className="d-flex align-items-center">
          <Typography
            className="bg-blue-color text-white-color"
            style={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",  
            }}
          >
            {data?.customer?.FirstName?.charAt(0)?.toUpperCase()}
            {data?.customer?.LastName?.charAt(0)?.toUpperCase()}
          </Typography>
          <Grid className="mx-2">
            <Typography className="mb-0" style={{ fontSize: "12px" }}>
              <b className="text-blue-color">
                {data?.customer?.FirstName || ""}
                {data?.customer?.LastName || ""}
              </b>
            </Typography>
            <Typography style={{ fontSize: "12px" }} className="text-blue-color">
              Created: {data?.updatedAt && formatDate(data?.updatedAt)}
            </Typography>
          </Grid>
        </Grid>
        <i>
          {Array.isArray(requestData) && requestData.length > 0 ? (
            (() => {
              let globalIndex = 1; 
              return requestData?.map((item, index) => (
                <Grid key={index}>
                  {Array.isArray(item?.RequestMessage) &&
                  item?.RequestMessage?.length > 0 ? (
                    item?.RequestMessage?.map((message, msgIndex) => {
                      const currentIndex = globalIndex++; 
                      return (
                        <Typography
                        className="text-blue-color createDateFooter"
                        key={msgIndex}
                        style={{ fontSize: "15px", display: "flex" }}
                      >
                        <b className="text-blue-color my-2">
                          Customer Request {currentIndex} :
                        </b>&nbsp;
                        <Typography className="my-2">
                          {message}
                          {item?.createdAt && (
                            <>
                              {" - "}
                              {new Date(item?.createdAt).toLocaleDateString("en-US", options)}
                            </>
                          )}
                        </Typography>
                      </Typography>
                      
                      );
                    })
                  ) : (
                    <Typography style={{ fontSize: "15px",marginTop:"10px" }}>
                      <b className="text-blue-color"> 
                        Customer Request {globalIndex++} :&nbsp;
                      </b>{" "}
                      {item?.RequestMessage}
                      {item?.createdAt && (
                        <>
                          {" - "}
                          {new Date(item?.createdAt).toLocaleDateString(
                            "en-US",
                            options
                          )}
                        </>
                      )}
                    </Typography>
                  )}
                </Grid>
              ));
            })()
          ) : (
            <></>
          )}
        </i>
      </Grid>
    </Grid>
  );
};

export default FooterDetails;
