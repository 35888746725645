// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 1680 */
@media (max-width: 1700px) {
  
}
.MinwidthPersantage{
    min-width: 100% !important;
}
/* 1500 */
@media (max-width: 1680px) {}

/* 1440 */
@media (max-width: 1449px) {
    .scrollDetailHere{
        min-width: 600px !important;
    }
  
    .overflowWidth{
        overflow-x: auto !important;
    }
}
.statusHead{
    background-color: #063164 !important;
    color: white !important;
}
/* 1280 */
@media (max-width: 1439px) {}

/* 992 */
@media (max-width: 1279px) {}

/* 768 */
@media (max-width: 991px) {}

/* 576 */
@media (max-width: 767px) {}

/* 525 */
@media (max-width: 575px) {}

/* 480 */
@media (max-width: 524px) {}

/* 425 */
@media (max-width: 479px) {}

/* 375 */
@media (max-width: 424px) {}

/* 320 */
@media (max-width: 374px) {
    .customerContractDetail{
        flex-direction: column;
    }
    .customerContractNumber{
        margin-right: auto;
    }
    .download-sec{
        flex-direction: column;
        gap: 6px !important;
        
    }
}

/* 280 */
@media (max-width: 319px) {}
`, "",{"version":3,"sources":["webpack://./src/Views/Customer/Contract/style.css"],"names":[],"mappings":"AAAA,SAAS;AACT;;AAEA;AACA;IACI,0BAA0B;AAC9B;AACA,SAAS;AACT,4BAA4B;;AAE5B,SAAS;AACT;IACI;QACI,2BAA2B;IAC/B;;IAEA;QACI,2BAA2B;IAC/B;AACJ;AACA;IACI,oCAAoC;IACpC,uBAAuB;AAC3B;AACA,SAAS;AACT,4BAA4B;;AAE5B,QAAQ;AACR,4BAA4B;;AAE5B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,kBAAkB;IACtB;IACA;QACI,sBAAsB;QACtB,mBAAmB;;IAEvB;AACJ;;AAEA,QAAQ;AACR,2BAA2B","sourcesContent":["/* 1680 */\n@media (max-width: 1700px) {\n  \n}\n.MinwidthPersantage{\n    min-width: 100% !important;\n}\n/* 1500 */\n@media (max-width: 1680px) {}\n\n/* 1440 */\n@media (max-width: 1449px) {\n    .scrollDetailHere{\n        min-width: 600px !important;\n    }\n  \n    .overflowWidth{\n        overflow-x: auto !important;\n    }\n}\n.statusHead{\n    background-color: #063164 !important;\n    color: white !important;\n}\n/* 1280 */\n@media (max-width: 1439px) {}\n\n/* 992 */\n@media (max-width: 1279px) {}\n\n/* 768 */\n@media (max-width: 991px) {}\n\n/* 576 */\n@media (max-width: 767px) {}\n\n/* 525 */\n@media (max-width: 575px) {}\n\n/* 480 */\n@media (max-width: 524px) {}\n\n/* 425 */\n@media (max-width: 479px) {}\n\n/* 375 */\n@media (max-width: 424px) {}\n\n/* 320 */\n@media (max-width: 374px) {\n    .customerContractDetail{\n        flex-direction: column;\n    }\n    .customerContractNumber{\n        margin-right: auto;\n    }\n    .download-sec{\n        flex-direction: column;\n        gap: 6px !important;\n        \n    }\n}\n\n/* 280 */\n@media (max-width: 319px) {}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
